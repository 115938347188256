import { useEffect } from "react";
import { useTokenContract } from "./useContract";
import { depositContract } from "../config";
import { useCallback } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { showError, showPromise } from "../utils/pageHelpers";
import useActiveWeb3React from "./useWeb3";
import { ethers } from "ethers";
import { formatEther } from "ethers/lib/utils";

export const useApprove = ({ token, inputAmount }) => {
    const [isApproved, setIsApproved] = useState(false);
    const [isApproving, setIsApproving] = useState(false);
    const { account } = useActiveWeb3React();
    const tokenInstance = useTokenContract(token);

    const approveCallback = useCallback(async () => {
        try {
            setIsApproving(true);
            const txn = await tokenInstance.approve(
                depositContract,
                ethers.constants.MaxUint256
            );
            await showPromise(txn.wait, "Approving...")
            setIsApproved(true);
            setIsApproving(false);
        } catch (error) {
            console.error(error);
            showError(error.reason ? error.reason : (error.data ? error.data.message : error.message));
            setIsApproved(false);
            setIsApproving(false);
        }
    }, [tokenInstance]);

    const fetchApproval = useCallback(async () => {
        try {
            if (tokenInstance && account) {
                const allowance = await tokenInstance.allowance(
                    account,
                    depositContract
                );
                setIsApproved(parseFloat(formatEther(allowance)) > parseFloat(inputAmount === "" ? "0" : inputAmount));
            }
        } catch (error) {
            console.error(error);
        }
    }, [tokenInstance, account, inputAmount]);

    useEffect(() => {
        fetchApproval();
    }, [fetchApproval]);

    useEffect(() => {
        const refreshInterval = setInterval(fetchApproval, 10000);
        return () => clearInterval(refreshInterval);
    }, [fetchApproval])

    return useMemo(() => {
        return {
            isApproved, isApproving, approveCallback
        }
    }, [isApproved, isApproving, approveCallback]);

}