import { depositContract, ogToken } from "../config"
import { useContract } from "./useContract"

import DepositABI from '../config/abis/stakeABI.json'
import { useCallback } from "react"
import { showPromise } from "../utils/pageHelpers"
import { parseEther } from "ethers/lib/utils"

export const useDeposit = () => {
    const contractInstance = useContract(depositContract, DepositABI)

    const deposit = useCallback(async ({ inputAmount, tokenAddress }) => {
        try {
            console.debug(tokenAddress.toLowerCase() === ogToken.toLowerCase())
            const txn = await contractInstance.topup(parseEther(inputAmount), tokenAddress.toLowerCase() === ogToken.toLowerCase())
            await showPromise(txn.wait, "Depositing...")
        } catch (error) {
            console.error(error)
        }
    }, [contractInstance])

    return { deposit }
}