import { Web3ReactProvider } from "@web3-react/core";
import Payementbox from "./Components/Payementcard/Payementbox";
import { Toaster } from "react-hot-toast";
import { Buffer } from 'buffer';
import 'sweetalert2/src/sweetalert2.scss';
import useDirectConnect from "./hooks/useDirectConnect";
import { getLibrary } from "./utils/web3React";

function Updaters() {
  useDirectConnect()
  return (<></>)
}

function Routes() {
  return (
    <div className="App">
      <Payementbox />
      <Toaster />
    </div>
  );
}

function App() {
  window.Buffer = Buffer
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Updaters />
      <Routes />
    </Web3ReactProvider>
  );
}

export default App;
