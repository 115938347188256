import axios from "axios"

export const fetchOCPrice = async () => {
    try {
        let ocPrice = await axios.get("https://oc-price-api.vercel.app/oc-price")
        // console.debug(parseFloat(ocPrice.data.price))
        return parseFloat(ocPrice.data.price)
    } catch (err) {
        console.log(err)
        return 0
    }
}