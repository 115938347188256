export const ocToken = "0x6eA4BaBF46AfC7895ee20594b86fDcF74526c3ec"
export const ogToken = "0x5953149Fcd40bd91b139DEDaC224Ff34fc26EfCD"

export const tokenDetails = {
    [ocToken]: {
        name: "OxChange",
        symbol: "OC",
        decimals: 18,
    },
    [ogToken]: {
        name: "OxGaming",
        symbol: "OG",
        decimals: 18,
    }
}

export const depositContract = "0x8c9d02D48c042Ce400D3E7d7bb8fdBF89ED755Bc"

export const EXPLORER_LINKS = 'https://polygonscan.com'
export const BASE_URL = 'https://stake-nft.hashfair.io/'
export const chainRPC = "https://delicate-blue-brook.matic.quiknode.pro/5a7dae00f9ce4fbea3ea4bee41ef47ca0b3dc35f/" //https://alpha-alpha-bird.matic-testnet.quiknode.pro/2d46a76d8bd8d402b6d048a255c308f77f684057/
export const networkName = "Polygon Mainnet"
export const minWithdraw = 10
export const defaultNetwork = 137 // 80001