import { useEffect, useMemo, useState } from "react"
import { useTokenContract } from "./useContract"
import useActiveWeb3React from "./useWeb3"
import { formatEther } from "ethers/lib/utils"

export const useAccountBalance = ({ token }) => {
    const tokenInstance = useTokenContract(token)
    const [balance, setBalance] = useState(0)
    const { account } = useActiveWeb3React()

    const fetchBalance = async () => {
        try {
            if (tokenInstance && account) {
                const balance = await tokenInstance.balanceOf(account)
                setBalance(formatEther(balance))
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetchBalance()
    }
        , [tokenInstance, account])

    useEffect(() => {
        const refreshInterval = setInterval(fetchBalance, 10000)
        return () => clearInterval(refreshInterval)
    }
        , [tokenInstance, account])

    return useMemo(() => balance, [balance])
}