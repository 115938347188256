import React from "react";
import "./Payementbox.css";
import logo from "../../Assets/images/oc-change-logo.png";
import ocLogo from "../../Assets/images/oc-change-logo.png";
import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useAccountBalance } from "../../hooks/useAccountBalance";
import { ocToken, ogToken, tokenDetails } from "../../config";
import { toLocale } from "../../utils";
import { useApprove } from "../../hooks/useApprove";
import { useCallback } from "react";
import useActiveWeb3React from "../../hooks/useWeb3";
import useAuth from "../../hooks/useAuth";
import { ConnectorNames } from "../../utils/web3React";
import { useDeposit } from "../../hooks/useDeposit";
import { showError } from "../../utils/pageHelpers";
import { useOCPrice } from "../../hooks/useOCPrice";

const Payementbox = () => {
  const [tokenAddress, setTokenAddress] = React.useState(ocToken);
  const [input, setInput] = React.useState(0)
  const { account } = useActiveWeb3React()
  const { login } = useAuth()
  const [loading, setLoading] = React.useState(false)

  const ocPrice = useOCPrice()

  const handleChange = (event) => {
    setTokenAddress(event.target.value);
  };

  const balance = useAccountBalance({ token: tokenAddress })
  const { isApproved, isApproving, approveCallback } = useApprove({ token: tokenAddress, inputAmount: input })
  const { deposit } = useDeposit()

  const approve = useCallback(() => {
    try {
      approveCallback()
    } catch (error) {
      console.log(error)
    }
  }, [approveCallback])

  const useMax = useCallback(() => {
    setInput(balance)
  }, [balance])

  const depositCallback = useCallback(async () => {
    try {
      if (!parseFloat(input) || parseFloat(input) <= 0) {
        showError("Please enter a valid amount")
        return
      }
      setLoading(true)
      await deposit({ tokenAddress: tokenAddress, inputAmount: input.toString() })
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }, [deposit, setLoading, tokenAddress, input])

  return (
    <>
      <section className="payementarea">
        <div className="payementbox">
          <div className="topbar">
            <div className="brandlogo">
              <img src={logo} alt="logo" />
            </div>
          </div>
          <div className="deposit-box">
            <div className="text-center">
              <h2>Deposit to OxGaming</h2>
              <span>There are many scam websites pretending to OxChange. Do check the url <strong>https://deposit.oxchange.finance/</strong> to be correct. </span>
              {account && <p>Make sure, you've updated <strong>{account}</strong> on <a target="_blank" style={{ color: "#0070ff" }} href="https://gaming.oxchange.finance/">https://gaming.oxchange.finance/</a> profile.</p>}
            </div>
          </div>
          <hr style={{ color: "white", margin: "10px 0 10px 0" }} />
          <Grid container spacing={2}>
            {account && <Grid item xs={12} width={"100%"} style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Available Balance:</span>
              <span> <strong>{toLocale(balance)} {tokenDetails[tokenAddress].symbol}</strong></span>
            </Grid>}
            {/* <Grid item xs={12}>
              <TextField
                fullWidth
                type="Card number"
                label="Card number"
                placeholder="**** **** **** ****"
              />
            </Grid>
            <Grid item sm={6} width={"100%"}>
              <TextField
                fullWidth
                type="Expiration date"
                label="Expiration date"
                placeholder="MM/YY"
              />
            </Grid>
            <Grid item sm={6} width={"100%"}>
              <TextField
                fullWidth
                type="Security code"
                label="Security code"
                placeholder="***"
              />y
            </Grid> */}
            <Grid item sm={6} width={"100%"}>
              <TextField
                fullWidth
                label="Amount"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="buttonbox">
                      <button className="maxbtn" onClick={useMax}>MAX</button>
                    </InputAdornment>
                  ),
                }}
                value={input}
                onChange={(e) => {
                  setInput(e.target.value)
                }}
              />
            </Grid>
            <Grid item sm={6} width={"100%"}>
              <Select
                value={tokenAddress}
                onChange={handleChange}
                defaultValue={ocToken}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={ocToken}>
                  <div className="currency-menubox">
                    <div className="image-box">
                      <img src={ocLogo} alt="logo" />
                    </div>
                    <span>OxChange ( OC )</span>
                  </div>
                </MenuItem>
                <MenuItem value={ogToken}>
                  <div className="currency-menubox">
                    <div className="image-box">
                      <img src={ocLogo} alt="logo" />
                    </div>
                    <span>OxGaming ( OG )</span>
                  </div>
                </MenuItem>
              </Select>
            </Grid>
            <div className="flex">
              {account && !isApproved && <Button
                type="submit"
                variant="contained"
                size="large"
                className="submitaddess"
                onClick={approve}
                disabled={isApproving}
              >
                {isApproving ? "Approving..." : "Approve"}
              </Button>}
              {account && isApproved && <Button
                type="submit"
                variant="contained"
                size="large"
                className="submitaddess"
                disabled={loading || isApproving || !isApproved}
                onClick={depositCallback}
              >
                {loading ? "Depositing..." : "Deposit"}
              </Button>}

              {!account && <Button
                type="submit"
                variant="contained"
                size="large"
                className="submitaddess"
                onClick={() => {
                  login(ConnectorNames.Injected)
                }}
              >
                Connect Wallet
              </Button>}
            </div>
            <Grid item sm={12} width={"100%"} className="text-center">
              <div >
                <p style={{ textAlign: "center" }}><strong>{tokenAddress.toLowerCase() === ogToken.toLowerCase() ? toLocale((input * 0.01) / ocPrice, 4) : toLocale(input)}</strong> will be deposited as <strong>{tokenAddress.toLowerCase() === ogToken.toLowerCase() ? "bonus" : "real"}</strong> credit.</p>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
};

export default Payementbox;
