import { useEffect, useState } from "react"
import { fetchOCPrice } from "../utils/fetchOCPrice"

export const useOCPrice = () => {
    const [ocPrice, setOcPrice] = useState(0)

    const fetchPrice = async () => {
        try {
            let price = await fetchOCPrice()
            setOcPrice(price)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchPrice()
    }
        , [])

    useEffect(() => {
        const interval = setInterval(() => {
            fetchPrice()
        }, 10000)
        return () => clearInterval(interval)
    }, [])

    return ocPrice
}