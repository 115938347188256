import useActiveWeb3React from "./useWeb3";
import { useMemo } from "react";
import { getContract } from "../utils/getContract";
import tokenABI from '../config/abis/tokenABI.json';
import nftABI from '../config/abis/IERC1155.json';
import nftManagerABI from '../config/abis/nftManager.json'

export const useContract = (address, abi) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getContract(address, abi, library.getSigner()), [address, abi, library]);
}

export function useTokenContract(tokenAddress) {
    return useContract(tokenAddress, tokenABI)
}

export function useNFTContract({ nftAddress }) {
    return useContract(nftAddress, nftABI)
}

export function useNFTManager() {
    return useContract("0x0512b356967e9Ef010EEE775476F13415e3343Ca", nftManagerABI)
}